@font-face {
  font-family: 'Greycliff';
  font-style: bold;
  font-weight: 700;

  src: url('./fonts/Greycliff-CF-Bold.otf') format('truetype'),
}

$sans-serif: "Greycliff", "helvetica", arial, sans-serif;
$serif: "baskerville", "times new roman", serif;

$dark-gray: #444;

*{
  box-sizing: border-box;
}

@keyframes bgfade {
  0% {
    background-image: url('./img/bg.jpg');
  }
  25% {
    background-image: url('./img/bg2.jpg');
  }
  50% {
    background-image: url('./img/bg3.jpg');
  }
  75% {
    background-image: url('./img/bg4.jpg');
  }
  100% {
    background-image: url('./img/bg.jpg');
  }
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 12px;
  background: #ddd;
  font-family: $sans-serif;
  color: $dark-gray;
  animation: bgfade 30s infinite;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  @media all and (min-width: 768px){
    padding: 30px;
  }
  &.bg-1{
    background-image: url('./img/bg2.jpg');
  }
  &.bg-2{
    background-image: url('./img/bg3.jpg');
  }
  &.bg-3{
    background-image: url('./img/bg4.jpg');
  }
  &.bg-4{
    background-image: url('./img/bg5.jpg');
  }
}

.Content {
  display: flex;
  flex-direction: column;
  width: 96%;
  max-width: 1200px;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 40px 50px;
  position: relative;



  @media all and (min-width: 768px){
    &:after{
      content: " ";
      background-image: url('./img/symbol.svg');
      width: 30vw;
      height: 30vw;
      position: absolute;
      background-size: contain;
      left: 85%;
      bottom: 70%;
      background-repeat: no-repeat;
      z-index: -1;

    }
  }
  a{
    color: $dark-gray;
    text-decoration: none;
  }
  header{
    display: flex;
    align-self: flex-end;
  }
  main{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 750px;
    text-align: center;
    padding: 6% 0 2%;
    p{
      font-family: $serif;
      padding-top: 15px;
      strong{
        font-family: $sans-serif;
      }
      &.Preamble{
        font-family: $sans-serif;
        font-size: 1.5em;
      }
    }

    img{
      width: 100%;
      max-width: 480px;
    }
  }
  .Contact{
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 80%;
    @media all and (min-width: 768px){
      flex-direction: row;
      justify-content: space-between;
    }
  }
  footer{
    border-top: 1px solid $dark-gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    width: 90%;
    >* {
      text-align: center;
      justify-content: center;
      align-items: center;
      img{
        max-height: 50px;
      }
    }
    @media all and (min-width: 768px){
      flex-direction: row;
      >*{
        margin: 0;
        width: 20%;
        img{
          max-height: 30px;
          max-width: 94%;
        }
      }
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
